import { render, staticRenderFns } from "./AddArticleComponent.vue?vue&type=template&id=16107db1&scoped=true&"
import script from "./AddArticleComponent.vue?vue&type=script&lang=js&"
export * from "./AddArticleComponent.vue?vue&type=script&lang=js&"
import style0 from "./AddArticleComponent.vue?vue&type=style&index=0&id=16107db1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16107db1",
  null
  
)

export default component.exports